import translations from "app/assets/files/key-value-translations/homepage.json";
import Segment from "app/components/page/Segment";
import PublicPage from "app/components/templates/PublicPage";
import { IProps } from "app/modules/common/type";
import { Grid } from "semantic-ui-react";
import "./Intro.scss";

export const Intro = (props: IProps): JSX.Element => (
	<PublicPage>
		<Segment>
			<Grid>
				<Grid.Row columns={12}>
					<Grid.Column
						mobile={12}
						tablet={6}
						computer={6}
						className="no-margin index__image-wrapper no-padding-top"
					>
						<div>
							<div className="index__header-margin" />
							<h1 className="index__header-title semi-bold mt0 mb0">
								{translations[props.language].homepageHeader.data}
							</h1>
							<h5 className="index__header-title mt16 mb0">
								{translations[props.language].homepageHeaderDescription.data}
							</h5>
						</div>
					</Grid.Column>
					<Grid.Column
						mobile={12}
						tablet={6}
						computer={6}
						className="no-margin index__image-wrapper no-padding-top"
					>
						<img
							className="index__image-intro"
							src="/images/mylegacy-hero.png"
							width={404}
							height={276}
							// eslint-disable-next-line react/no-unknown-property
							fetchpriority="high"
							alt={translations[props.language].homepageIntroImage.data}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	</PublicPage>
);

export default Intro;
