import translations from "app/assets/files/key-value-translations/homepage.json";
import { CardWithImageAndPill } from "app/components/basic/Card";
import Pill, { PillType } from "app/components/basic/Pill";
import Segment from "app/components/page/Segment";
import { IProps } from "app/modules/common/type";
import { Grid } from "semantic-ui-react";

export const LatestNews = (props: IProps): JSX.Element => {
	const renderButton = <Pill text={"Campaign"} type={PillType.neutral} isTag={false} />;
	return (
		<Segment className="landing-page-bottom-margin">
			<Grid>
				<Grid.Row columns={12}>
					<Grid.Column mobile={12} tablet={12} computer={12} className="no-margin">
						<h4 className="index__intro-title semi-bold">{"Latest news"}</h4>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={12}>
					<Grid.Column mobile={12} tablet={6} computer={6} className="index__card">
						<CardWithImageAndPill
							id="gift-of-certainty"
							header={"Gift of Certainty"}
							description={"Find out more about the LPA and ACP before getting started."}
							image="/images/gift-of-certainty.png"
							imageAltText={translations[props.language].homepageEOLPlanningImage.data}
							href="https://www.giftofcertainty.gov.sg/home/"
							buttonBelowImage={renderButton}
							PillText="Campaign"
						/>
					</Grid.Column>
					<Grid.Column mobile={12} tablet={6} computer={6} className="index__card"></Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default LatestNews;
